import React from 'react'
import {YoutubeVideoStoryblok} from 'types/storyblok-types'

type VideoPropsType = {
  url: string
}

export const VideoBlock = ({url}: VideoPropsType) => {
  return (
    <div className="flex aspect-video w-full flex-col overflow-hidden">
      <iframe
        src={url}
        allow="encrypted-media; picture-in-picture; web-share"
        allowFullScreen
        title=""
        className="flex-1 rounded-md"
      />
    </div>
  )
}

type VideoContainerProps = {
  blok: YoutubeVideoStoryblok
}
const VideoContainer = ({blok}: VideoContainerProps) => {
  if (blok.url?.url) {
    return <VideoBlock url={blok.url?.url ?? ''} />
  } else {
    return null
  }
}

export default VideoContainer
